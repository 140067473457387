.flickity-slider {
    width: 100%;
}

.carousel-cell {
    width: 280px;
    height: 130px;
    margin-right: 10px;
    background: #8C8;
    border-radius: 5px;
    counter-increment: carousel-cell;
}

.carousel .carousel-cell {
    margin: 0 6px;
    border-radius: 5px;
    overflow: hidden;
}