// body {
//     background-color: #111111;
// }

// Header
.main-header {
    background: #111111;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2px 0;
    position: sticky;
    top: 0;
    z-index: 9;

    .left-side {
        padding: 5px 10px;
    }

    .center-side {
        padding: 5px 10px;
    }

    .right-side {
        div {
            padding: 5px 10px;
            text-align: center;
            display: flex;
            flex-direction: column;

            img {
                margin: 0 auto;
            }

            span {
                font-size: 16px;
                color: #137F5D;
            }
        }
    }
}

.home-sports-items {
    // display: flex;
    // overflow-x: scroll;
    background-color: #111111;
    // position: sticky;
    // top: 50px;
    // z-index: 9;
    position: sticky;
    top: -2px;
    z-index: 1;
    .sport-item {
        padding: 15px;
        text-align: center;
        flex-shrink: 0;

        &.active {
            background-color: #252525;
        }

        .sport-item-img {
            img {
                margin: 0 auto 5px;
                height: 45px;
            }

            &:hover {
                img {
                    -webkit-transform: scale3d(1.1, 1.1, 1);
                    transform: scale3d(1.1, 1.1, 1);
                }
            }
        }

        .sport-item-txt {
            color: #ffffff;
            font-size: 16px;
            line-height: 1.5;
        }
    }
    .slick-dots{
        display: none !important;
    }
}

.marquee-box {
    color: #fff;
    line-height: 35px;
    height: 29px;
    margin-bottom: 1px;
    background-repeat: repeat-x;
    display: flex;
    align-items: center;
    padding: 0 8px;

    .marquee {
        // flex: 1;
        overflow: hidden;
        z-index: 1;
        width: 100%;
        display: flex;

        .js-marquee-wrapper {
            animation-duration: 15s;
            animation-iteration-count: infinite;
            animation-name: marquee-efef9c71;
            animation-timing-function: linear;
            display: inline-flex;
            align-items: center;
            position: relative;
            // .js-marquee {
            //     margin-right: 0px;
            //     float: left;
            // }
        }

        a {
            height: 35px;
            line-height: 35px;
            font-size: 14px;
            color: #fff;
            align-items: center;
            float: left;
            white-space: nowrap;

            span {
                height: 15px;
                line-height: 15px;
                font-size: 11px;
                font-style: italic;
                font-weight: normal;
                color: #1c2834;
                background-color: #6ac2ff;
                border-radius: 2px;
                padding: 0 5px;
                margin-right: 5px;
            }
        }
    }
}

@keyframes marquee-efef9c71 {
    0% {
        left: 100%;
        transform: translate(0)
    }

    to {
        left: 0;
        transform: translate(-100%)
    }
}

.sports-filed {
    padding: 0 5px;

    .sports-list-field {
        .label {
            border-left: 5px solid #666666;
            padding: 0 5px;
            color: #fff;
            margin: 10px 0;
            font-size: 16px;
            font-weight: 700;
        }

        .item {
            width: 24.5%;
            background-color: #333333;
            color: white;
            text-align: center;
            display: inline-block;
            margin-bottom: 1.5px;
            margin-right: 1.5px;
            padding-top: 12px;
            padding-bottom: 12px;
            font-size: 14.4078px;
            line-height: 1.5;

            img {
                height: 37.55px;
                margin: 0 auto;
            }

            &:hover {
                img {
                    -webkit-transform: scale3d(1.2, 1.2, 1);
                    transform: scale3d(1.2, 1.2, 1);
                }
            }
        }
    }
}

.favo {
    background-color: #333333;
    padding: 10px;
    margin-top: 10px;

    .label {
        border-left: 5px solid #666666;
        padding: 0 5px;
        color: #fff;
        margin: 10px 0;
        font-size: 16px;
        font-weight: 700;
    }

    .x-scroll-box-1 {
        display: flex;
        overflow-x: auto;
        padding-bottom: 6px;

        .scroll-image {
            flex-shrink: 0;
            display: inline-block;
            height: 160px;
            width: 300px;
            margin-right: 12px;
            border-radius: 10px;
            overflow: hidden;
            border: 2px solid #fff3;

            img {
                height: 100%;
                width: 100%;
                box-shadow: inset 0 0 15px rgba(220, 220, 220, 1);
                object-fit: cover;
                display: block;
            }

            &:hover {
                img {
                    -webkit-transform: scale3d(1.2, 1.2, 1);
                    transform: scale3d(1.2, 1.2, 1);
                }
            }
        }
    }

    .x-scroll-box-2 {
        display: flex;
        overflow-x: auto;
        padding-bottom: 6px;

        .scroll-image {
            flex-shrink: 0;
            height: 140px;
            width: 190px;
            margin-right: 12px;
            display: inline-block;

            img {
                height: 100%;
                width: 100%;
            }

            &:hover {
                img {
                    -webkit-transform: scale3d(1.2, 1.2, 1);
                    transform: scale3d(1.2, 1.2, 1);
                }
            }
        }
    }
}

.my-footer {
    padding: 15px;

    .footer-label {
        padding: 10px 0;
        color: #127E5C;
        font-size: 15px;
        font-weight: 500;
    }

    .payment-method-icon {
        img {
            height: 17px;
            display: inline-block;
            margin: 5px 5px 5px 0;
            -webkit-filter: brightness(60%);
        }
    }

    .dual-grid {
        display: grid;
        grid-template-columns: 52% 48%;

        &.app-lic {
            border-top: 1px solid #333;
            border-bottom: 1px solid #333;
            padding: 6px 0 25px 0;
            margin-top: 25px;
        }

        .safety-icon {
            img {
                height: 25px;
                margin: 5px 10px 5px 0;
                -webkit-filter: brightness(60%);
                display: inline-block;
            }
        }
    }

    .footer-desc {
        color: #888888;
        font-size: 13px;
        line-height: 16px;
        border-bottom: 1px solid #333;
        padding: 15px 0;
    }

    .copyright {
        color: #888888;
        font-size: 14px;
        padding-bottom: 46px;

        table {
            width: 100%;
            border-collapse: collapse;

            tr {
                td {
                    padding: 20px 0;

                    &:first-child {
                        width: 70px;
                        padding-right: 20px;
                    }
                }
            }
        }
    }
}

.footer-btn {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    display: grid;
    grid-template-columns: 30% 40% 30%;



    div {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        padding: 6px;
        height: 50px;
    }

    .button1 {
        background-color: #D7E3F1;
        font-size: 12px;

        img {
            margin-right: 10px;
            height: 28px;
        }
    }

    .button2 {
        background-color: #FFDF1A;
        font-size: 16px;
    }

    .button3 {
        background-color: #14805E;
        color: #fff;
        font-size: 16px;
    }

}


// Deposit
.logged-footer-btn {
    background-color: #111;
    color: #ffffff;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    font-size: 12px;
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    height: 50px;
    border-top: 1px solid #666;

    div {
        text-align: center;
        font-size: 12px;
        line-height: 16px;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;

        &::after {
            content: "";
            display: block;
            position: absolute;
            z-index: -1;
            top: 50%;
            left: 50%;
            width: 10.6666666667vw;
            height: 10.6666666667vw;
            transform: translate(-50%, -50%) scale(0);
            transition: all .3s;
            border-radius: 10.6666666667vw;
            background: #333333;
        }

        &.b-active {
            &::after {
                animation: toolbar-active-animation .4s ease-in-out;
                animation-fill-mode: forwards;
            }

        }

        &.b-active::after {
            animation: toolbar-active-animation .4s ease-in-out;
            animation-fill-mode: forwards;
        }

        img {
            height: 28px;
            padding-top: 6px;
            -webkit-filter: brightness(60%);
            margin: 0 auto;
        }
    }
}

@keyframes toolbar-active-animation {
    0% {
        transform: translate(-50%, -50%) scale(0)
    }

    85% {
        transform: translate(-50%, -50%) scale(1.2)
    }

    90% {
        transform: translate(-50%, -50%) scale(.9)
    }

    95% {
        transform: translate(-50%, -50%) scale(1.1)
    }

    to {
        transform: translate(-50%, -50%) scale(1)
    }
}

.mob-header {
    background: #14805E;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    position: sticky;
    top: 0;
    z-index: 9;

    .back {
        padding: 5px 10px;
    }

    .center {
        padding: 5px 10px;

        h5 {
            font-size: 16px;
            list-style: 16px;
            font-weight: 600;
            color: #ffffff;
        }
    }

    .help {
        padding: 5px 10px;
        display: flex;
        flex-direction: column;

        span {
            font-size: 12px;
            color: #ffffff;
        }
    }
}

.tab-deposit-withdraw {
    background-color: #14805E;
    padding: 15px;

    .btn-grp {
        width: 320px;
        background-color: #444;
        border-radius: 7px;
        margin: 6px auto;
        color: #fff;
        position: relative;

        .inv-btn {
            width: 155px;
            text-align: center;
            background-color: transparent;
            display: inline-block;
            padding: 6px;
            z-index: 2;
            position: relative;
            font-size: 16px;
            line-height: 16px;
        }

        .btn-stick {
            background-color: #34AF83;
            padding: 7px;
            width: 160px;
            height: 35px;
            top: -4px;
            left: -5px;
            position: absolute;
            z-index: 1;
            border-radius: 5px;
            box-shadow: 0 0 7px rgba(0, 0, 0, 0.1);
            transition: 0.5s;
        }

        .active {
            // background-color: #14805E;
            left: 160px;
        }
    }
}

.promotion-stick {
    background-color: #36BC8A;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    padding: 10px;

    .prom-left {
        font-size: 14px;
        font-weight: 500;
        color: #ffffff;
        display: flex;
        align-items: center;

        img {
            height: 28px;
            margin-right: 6px;
        }
    }

    .prom-right {
        select {
            font-size: 12px;
            background-color: transparent;
            border: none;
            color: white;
            font-weight: 300;
        }
    }
}

.pm-box {
    background-color: #333;
    padding: 10px;
    margin-top: 10px;

    .box-title {
        padding: 7px 12px;
        border-bottom: 1px solid #555;

        span {
            color: #eee;
            border-left: 4px solid #797;
            padding-left: 8px;
            font-size: 16px;
        }
    }

    .pm-grid {
        display: grid;
        grid-template-columns: auto auto auto;
        grid-gap: 10px;
        margin-top: 10px;
        padding-bottom: 10px;
        border-bottom: 1px dashed #666;

        .pm {
            border: 1px solid #555;
            border-radius: 4px;
            height: 70px;
            text-align: center;
            color: #fff;
            font-size: 12px;
            position: relative;

            .bank-name {
                font-size: 12px;
            }

            .bank-icon {
                height: 35px;
                -webkit-filter: brightness(80%);
                margin: 7px auto 0;
            }

            .ribbon {
                height: 22px;
                width: 40px;
                position: absolute;
                right: 0;
                top: 4px;
                transition: 0.5s linear;
                opacity: 1;

                img {
                    position: absolute;
                    height: 100%;
                    width: 100%;
                    right: 0;
                }

                span {
                    top: 2px;
                    right: 4px;
                    position: relative;
                }
            }

            .bottom-tick {
                bottom: -1px;
                right: -1px;
                height: 20px;
                position: absolute;
                vertical-align: middle;
            }
        }
    }

    .pm-confirm-btn {
        background-color: #FFDF1A;
        border: none;
        padding: 8px 35px;
        margin: 10px 0;
        border-radius: 4px;
        font-size: 16px;
        line-height: 24px;
    }

    .pm-grid-btn {
        margin-top: 10px;

        button {
            border: 1px solid #555;
            border-radius: 4px;
            background-color: transparent;
            text-align: center;
            color: #fff;
            font-size: 14px;
            padding: 10px 20px;
            margin-right: 8px;
        }
    }

    .amnt-ttl {
        display: flex;
        justify-content: space-between;

        span:last-child {
            border: none;
            font-size: 12px;
        }
    }

    .am-grid-btn {
        display: grid;
        grid-template-columns: auto auto auto auto;
        grid-gap: 10px;
        margin-top: 10px;
        padding-bottom: 10px;
        border-bottom: 1px dashed #666;
        transition: 0.3s;

        .am-btn {
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #555;
            border-radius: 4px;
            background-color: transparent;
            text-align: center;
            color: #fff;
            font-size: 14px;
            transition: all 1s;
            position: relative;
        }
    }

    .amount-list-bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 45px;
        border-bottom: 1px solid #555;
        padding: 10px;
        background-color: #000;

        #currency {
            color: #FFDF1A;
            font-weight: 700;
            font-size: 16px;
        }

        #total-deposit-amount {
            color: #5d3;
            font-size: 16px;
        }
    }

    .notice {
        display: flex;
        background-color: #111;
        padding: 10px;
        border-radius: 5px;
        font-size: 13px;
        color: #ddd;
        line-height: 16px;
        margin: 10px 0;

        div:first-child {
            width: 30px;
            margin-right: 5px;
            flex-shrink: 0;

        }
    }
}

.add-number {
    input {
        width: 100%;
        padding: 0 .625rem;
        border: 1px solid rgba(255, 255, 255, .5);
        border-radius: 3px;
        background: 0 0;
        color: #ffdf18;
        line-height: 2.375rem;
        margin-top: 10px;
    }
}

#submit-pm-btn {
    background-color: #14805E;
    padding: 12px;
    color: white;
    width: 100%;
    border-radius: 5px;
    margin: 10px 0;
    border: none;
    font-size: 16px;
}

.withdrawal-blnc {
    background-color: #14805E;
    color: white;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:last-child {
        padding-bottom: 20px;
    }

    .main-wallet-txt {
        font-size: 16px;
    }

    .main-balance {
        font-size: 26px;
        font-weight: 700;
        text-align: right;
        // margin: 10px;
    }

    &.small {
        padding: 0 10px;

        &:last-child {
            padding-bottom: 20px;
        }

        img {
            width: 14px;
            margin-left: 4px;
        }

        .main-wallet-txt {
            font-size: 14px;

        }

        .main-balance {
            font-size: 16px;
            font-weight: 500;
            text-align: right;
            // margin: 10px;
        }
    }
}

.required-board {
    background-color: #111;
    border-radius: 5px;
    border: 1px solid #333;
    margin: 15px;

    table {
        caption-side: bottom;
        border-collapse: collapse;
        color: #fff;
        border-bottom: 1px solid #333;
        width: 100%;

        td {
            padding: 5px;
            line-height: 16px;
            font-size: 16px;

            img {
                width: 50px;
            }
        }
    }

    .req-ttl2 {
        color: #419FFF;
        border-left: 4px solid #419FFF;
        margin-top: 10px;
        margin-bottom: 5px;
        padding-left: 10px;
        font-size: 14px;
        line-height: 20px;

    }

    .req2 {
        background-color: #419FFF;
    }

    .req1,
    .req2 {
        padding: 3px 12px;
        border-radius: 20px;
        font-size: 12px;
        margin-left: 15px;
    }
}

.login-title {
    background: #333333;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0;
    position: sticky;
    top: 0;
    z-index: 9;

    .back {
        padding: 5px 10px;
    }

    .center {
        padding: 5px 10px;
        flex-grow: 1;
        text-align: center;

        h5 {
            font-size: 16px;
            list-style: 16px;
            font-weight: 600;
            color: #ffffff;
        }
    }
}

.login-page-container {
    margin: 0 10px;

    .logo {
        text-align: center;
        padding: 12px;

        img {
            height: 45px;
            margin: 0 auto;
        }
    }

    .form {
        background-color: #222222;
        border-radius: 3px;
        overflow: hidden;
        margin-bottom: 10px;

        .form-line {
            position: relative;
            display: flex;
            align-items: center;
            padding: 14px;
            background-color: #333333;
            border-bottom: 1px solid #666666;

            &:last-child {
                border-bottom: 0;
            }

            #code {
                background-color: #ddd;
                padding: 10px;
                letter-spacing: 4px;
                font-weight: 900;
                position: absolute;
                right: 14px;
                margin-right: 10px;
                font-size: 16px;

                &::after {
                    content: "";
                    height: 2px;
                    width: 60px;
                    background-color: #333;
                    position: absolute;
                    left: 5px;
                    top: 20px;
                }
            }
            .get-code {
                position: absolute;
                background-color: #127E5C;
                color: #ffffff;
                font-size: 14px;
                right: 10px;
                border-radius: 8px;
                padding: 4px 6px;
                cursor: pointer;
            }
        }

        .form-label {
            color: white;
            font-size: 14px;
            width: 25%;
        }

        input {
            border: none;
            background-color: transparent;
            // color: #888;
            color: #fff;
            box-shadow: none;
            margin: 0;
            font-size: 14px;
        }

        select {
            background-color: transparent;
            border: none;
            color: #eee;
            font-weight: 500;
            font-size: 13px;
            width: 200px;

            option {
                background-color: #000;
            }
        }

        .form-action {
            width: 60px;

            span:first-child {
                color: #777;
            }

            span:last-child {
                color: #eee;
                margin-left: 15px;
            }
        }
    }

    .forgot-btn {
        text-align: right;

        button {
            border: 1px solid #777;
            border-radius: 3px;
            background-color: transparent;
            color: #777;
            margin-top: 10px;
            font-size: 16px;
            line-height: 24px;
        }
    }

    .login-btn {
        button {
            background-color: #14805E;
            color: white;
            padding: 13px;
            border: none;
            border-radius: 4px;
            width: 100%;
            margin-top: 10px;
            font-size: 16px;
            line-height: 24px;
        }
    }

    .dont-have-acc {
        text-align: center;
        padding: 5px;

        span {
            font-size: 16px;
            line-height: 24px;
            font-weight: 500;

            &:first-child {
                color: #fff;
            }

            &:last-child {
                color: #FEB421;
            }
        }
    }
}

.sprt-menu {
    display: grid;
    grid-template-columns: 50px auto 50px;
    border-radius: 4px;
    overflow: hidden;
    /* background-color: white; */
    height: 50px;

    #all-btn {
        background-color: #233A32;
        width: 50px;
        height: 50px;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid transparent;

        img {
            height: 35px;
        }
    }

    .list {
        display: flex;
        overflow-x: auto;
        overflow-y: hidden;

        /* align-items: center; */
        .list-width {
            display: flex;

            .list-btn {
                background-color: #233A32;
                height: 50px;
                width: 50px;
                border-radius: 3px;
                margin: 0 3px;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: 0.3s;

                &.active {
                    border: 1px solid #27775E;
                }

                &:hover {
                    img {
                        -webkit-transform: scale3d(1.1, 1.1, 1);
                        transform: scale3d(1.1, 1.1, 1);
                    }
                }
            }
        }
    }

    .search {
        background-color: #14805E;
        height: 50px;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 22px;
    }
}

.sports-grid {
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 10px;
    margin: 5px;

    .item {
        height: 150px;
        border-radius: 5px;
        overflow: hidden;
        transition: 0.3s;

        .image {
            height: 120px;

            img {
                height: 100%;
                width: 100%;
            }

            &:hover {
                img {
                    -webkit-transform: scale3d(1.1, 1.1, 1);
                    transform: scale3d(1.1, 1.1, 1);
                }
            }
        }

        .item-footer {
            background-color: #333333;
            height: 30px;
            color: white;
            display: flex;
            align-items: center;
            padding-left: 5px;
            font-size: 16px;
            line-height: 24px;
        }
    }
}


.profile-page {
    background-color: #111;
    height: 100%;
    width: 100%;
    top: 100%;
    left: 0;
    position: fixed;
    z-index: 15;
    overflow: hidden;
    transition: 0.4s;

    &.active {
        top: 0;
    }

    #close-profile {
        font-size: 25px;
        width: 60px;
        height: 60px;
        background-color: #000;
        border-radius: 0 0 0 100%;
        color: #999;
        position: absolute;
        right: 0;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 2;
    }

    .circle-design {
        height: 400px;
        width: 400px;
        background: linear-gradient(to bottom, #2E8167, #6EC1A7);
        position: absolute;
        border-radius: 50%;
        z-index: -1;
        top: -200px;
    }

    .scrolling-y {
        overflow-y: scroll;
        /* background-color: #999; */
        height: 100%;
        width: 100%;
        position: relative;
        top: 0;
    }

    .league-box {
        /* background-color: #999; */
        margin: 20px 10px;
        display: flex;

        .league {
            margin-right: 20px;

            img {
                height: 60px;
            }
        }

        .person {
            .person-name {
                text-transform: capitalize;
                font-size: 18px;
                font-weight: 700;
                color: #eee;
                margin-bottom: 8px;
            }

            .person-vp {
                display: inline;
                background-color: #222;
                border-radius: 15px;
                padding: 4px 10px;
                color: #eee;
                font-size: 12px;
                font-weight: 500;

                span {
                    font-size: 14px;
                }
            }
        }
    }

    .box {
        background-color: #333;
        margin: 10px;
        overflow: hidden;
        border-radius: 5px;

        .box-title {
            padding: 7px 12px;
            border-bottom: 1px solid #555;

            span {
                color: #eee;
                border-left: 4px solid #797;
                padding-left: 8px;
                font-size: 16px;
            }
        }

        .box-content {
            display: flex;
            color: #ddd;
            align-items: center;
            height: 70px;
            font-size: 12px;

            &.funds {
                text-align: center;

                .f {
                    width: 50%;
                    text-align: center;
                }

                img {
                    height: 50px;
                    margin-bottom: -10px;
                    -webkit-filter: brightness(90%);
                }
            }

            &.history {
                .h {
                    width: 33%;
                    text-align: center;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                }

                img {
                    height: 50px;
                    margin-bottom: -5px;
                    -webkit-filter: brightness(90%);
                }
            }

            &.profile {
                .p {
                    width: 25%;
                    text-align: center;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                }

                img {
                    height: 50px;
                    margin-bottom: -5px;
                    -webkit-filter: brightness(90%);
                }
                .position-absolute{
                    position: absolute;
                    width: 14px;
                    height: 14px;
                    border-radius: 50%;
                    background-color: red;
                    color: #ffffff;
                    font-size: 12px;
                    line-height: 14px;
                    top: 0;
                    right: 0;
                }
            }

            &.contact {
                .c {
                    width: 33%;
                    text-align: center;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    color: #ddd;
                }

                img {
                    height: 50px;
                    margin-bottom: -5px;
                    -webkit-filter: brightness(90%);
                }
            }

            &.logout {
                display: flex;
                justify-content: center;
                height: 45px;

                #l {
                    height: 50px;
                    margin-right: 5px;
                    -webkit-filter: brightness(90%);
                }
            }
        }

    }

    .profile-balance {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 70px;
        color: #999;
        padding: 0 10px;
        margin-top: 5px;

        .main-wallet-icon {
            display: flex;
            align-items: center;

            span {
                margin-left: 10px;

                svg {
                    color: #127E5C;
                    font-size: 13px;
                }
            }
        }
    }
}

.slide-btns-box {
    background-color: #14805E;
    position: relative;

    .slide-btns {
        display: flex;
        height: 60px;

        .slide-btn-left,
        .slide-btn-right {
            width: 50%;
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            font-weight: 500;
            transition: 0.3s;
            position: relative;

            &.active {
                color: #FFDF1A;

                &::before {
                    opacity: 1;
                }

            }

            &::before {
                content: "";
                position: absolute;
                height: 4px;
                width: 100%;
                border-radius: 5px;
                background-color: #FFDF1A;
                bottom: 0px;
                left: 0;
                transition: 0.5s;
                opacity: 0;
            }
        }

    }
}

.gift-box {
    background-color: #333;
    margin: 45px 10px 10px 10px;
    border-radius: 10px;
    height: 120px;
    position: relative;

    .gift-pelatte {
        height: 70px;
        width: 70px;
        background-color: #555;
        border-radius: 50%;
        position: absolute;
        top: -20px;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);

        .gift-pelatte img {
            height: 50px;
            width: 40px;
            transition: 0.4s;
        }
    }

    .bx {
        font-size: 16px;
        display: flex;
        margin: 0 10px;

        .subBx1,
        .subBx2 {
            height: 30px;
        }

        .subBx1 {
            width: 100%;
            border-radius: 5px;
            overflow: hidden;
            display: flex;

            .subsubBx1 {
                width: 83%;
                background-color: #222;
                color: #fff;
                font-weight: 700;
                display: flex;
                align-items: center;
                padding: 0 10px;
                letter-spacing: 1.5px;
                user-select: text;

                #textToCopy {
                    background-color: transparent;
                    border: none;
                    color: white;
                }
            }

            .subsubBx2 {
                background-color: #FFDF1A;
                width: 30px;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 0 3px 3px 0;

                img {
                    height: 18px;
                }
            }
        }

        .subBx2 {
            background-color: #FFDF1A;
            width: 145px;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

.referral-status-box {
    background-color: #333;
    border-radius: 10px;
    height: 120px;
    margin: 0 10px;

    .ref-title {
        color: white;
        padding: 0 10px;
        border-left: 4px solid #14805E;
        margin: 0 10px;
        font-size: 14px;
    }

    .ref-points {
        display: flex;
        font-size: 16px;

        .ref-point1,
        .ref-point2 {
            width: 50%;
            height: 70px;
            text-align: center;
            color: white;
        }

        .ref-point1 {
            border-right: 2px dotted #666;
        }

        .point1 {
            font-size: 32px;
            font-weight: 500;
            color: #999;
        }

        .point2 {
            font-size: 32px;
            font-weight: 500;
            color: #FFDF1A;
        }
    }
}

.records-timing-box {
    display: flex;
    align-items: center;
    height: 45px;
    background-color: #393939;

    span {
        background-color: #1EC28F;
        padding: 1px 7px;
        color: white;
        border-radius: 3px;
        font-size: 12px;
    }

    .records7days {
        display: flex;
        align-items: center;
        height: 45px;
        flex-grow: 1;
        padding-left: 10px;
    }

    .filter {
        display: flex;
        align-items: center;
        width: 45px;
        height: 45px;
        border-left: 1px solid #777;
        justify-content: center;
        flex-shrink: 0;
    }
}

.records-th {
    background-color: #4C4C4C;
    height: 33px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid #666;

    div {
        border-right: 1px dotted #fff;
        height: 20px;
        width: 25%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        font-size: 12px;

        &:last-child {
            border: none;
        }
    }
}

.empty-records {
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #777;
    text-align: center;
    font-size: 14px;

}


.mob-black-bg {
    z-index: 99;
    background-color: rgba(0, 0, 0, 0.8);
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    visibility: hidden;
    opacity: 0;
    transition: 0.3s;
}

.mob-left-panel {
    z-index: 999;
    background-color: #000;
    width: 200px;
    height: 100%;
    overflow-y: scroll;
    position: fixed;
    top: 0;
    left: -220px;
    transition: 0.3s;

    .panel-ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            background-color: #252525;
            padding: 4px 6px;
            color: #fff;
            font-size: 15px;
            border-bottom: 2px solid #3D3D3D;
            display: flex;
            align-items: center;
            transition: 0.3s;
            font-size: 15px;
            font-weight: 700;

            .circle {
                img {
                    height: 35px;
                    margin-right: 10px;
                }
            }
        }

        .active-menu {
            background-color: rgb(51, 51, 51);
            border-left: 3px solid rgb(19, 131, 95);
        }
    }

    .panel-last-ul {
        list-style: none;
        margin: 0;
        padding: 1px;
        background-color: #252525;

        li {
            background-color: #333333;
            padding: 6px;
            display: flex;
            align-items: center;
            color: #fff;
            margin: 10px;
            border: 1px solid #444444;
            border-radius: 5px;
            font-size: 16px;
            font-weight: 700;

            .circle {
                img {
                    height: 35px;
                    margin-right: 10px;
                }
            }
        }
    }
}

.sub-left-panel {
    // z-index: 999;
    position: fixed;
    left: 100px;
    // left: -170px;
    // left: 200px;
    top: 0;
    height: 100%;
    width: 100px;
    background-color: #333333;
    transition: 0.3s;
    padding: 5px;
    overflow-y: scroll;
    color: #fff;

    .sport-icon {
        width: 100%;
        text-align: center;
        padding: 20px 0;
        border-bottom: 1px solid #444;

        img {
            height: 45px;
            -webkit-filter: brightness(200%);
        }
    }
}

.announce-box {
    .announce-date-time {
        display: flex;
        height: 30px;
        align-items: center;
        color: #828282;
        padding: 8px;
        font-size: 14px;

        img {
            height: 16px;
            margin-right: 10px;
        }
    }

    .announce-part {
        background-color: #444;
        display: flex;
        align-items: center;
        padding: 5px;
        border-bottom: 1px solid #666;

        .announce-left {
            width: 50px;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                height: 30px;
                transform: rotate(-25deg);
                background-color: #f90;
                border-radius: 50%;
                padding: 5px;
            }
        }

        .announce-right {
            width: 100%;
            white-space: nowrap;
            width: 87%;

            .announce-right-top {
                display: flex;
                justify-content: space-between;
                width: 100%;
                font-size: 16px;
                color: white;
            }

            .announce-short-details {
                overflow: hidden;
                text-overflow: ellipsis;
                color: #828282;
                width: 100%;
                font-size: 16px;
            }

            .announce-time {
                font-size: 13px;
                color: #828282;
            }
        }
    }
}

.reset-passwrod-box {
    .reset-form {
        margin: 10px;
        border-radius: 4px;
        overflow: hidden;
        background-color: #333;
        padding: 0 5px;

        .reset-form-line {
            background-color: #333;
            display: flex;
            align-items: center;
            height: 45px;
            font-size: 13px;
            color: white;
            padding: 0 5px;
            border-bottom: 1px solid #555;

            &:last-child {
                border: none;
            }

            .reset-form-label {
                width: 100px;
                line-height: 14px;
            }

            input {
                background-color: transparent;
                border: none;
                color: #14805E;
            }
        }
    }
}

.req-box {
    font-size: 13px;
    color: white;
    border: 1px solid #555;
    margin: 10px;
    border-radius: 5px;
    padding: 10px;

    #must-length,
    #must-uppercase,
    #must-lowercase,
    #must-number {
        color: red;
    }

    img {
        vertical-align: middle;
        margin-right: 6px;
    }
}

#reset-confirm {
    background-color: rgb(68, 68, 68);
    font-size: 16px;
    color: rgb(153, 153, 153);
    padding: 10px;
    border-radius: 3px;
    margin: 10px;
    text-align: center;
    transition: 0.3s;
}

.personal-info {
    .sports-image {
        height: 150px;
        margin: 15px;
        border-radius: 15px;
        position: relative;

        .pattern-image {
            height: 100%;
            width: 100%;
            filter: brightness(70%);
        }

        .league-palette {
            /* background-image: -webkit-radial-gradient(#fff 0.1%, #000 90%); */
            background-image: -webkit-radial-gradient(#999, #333);
            border: 6px solid #111;
            position: absolute;
            height: 100px;
            width: 100px;
            left: 50%;
            transform: translateX(-50%);
            border-radius: 50%;
            bottom: -60px;
            /* box-shadow: 0 0 15px rgba(0,0,0,0.5); */
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                height: 60px;
            }
        }
    }

    .user-fullname {
        padding: 15px;
        text-align: center;
        color: white;
        font-size: 16px;
        margin-top: 50px;

        .user-league {
            padding: 4px 12px;
            background-color: #555;
            color: white;
            font-size: 14px;
            border-radius: 20px;
            margin-left: 5px;
        }

        .user-username {
            color: #888;
            font-size: 14px;
        }
    }

    .vip-stick {
        display: grid;
        background-color: #333333;
        grid-template-columns: 50% 50%;
        height: 50px;

        .vip-stick-div1 {
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: white;

            div {
                padding: 0 15px;

                &:last-child {
                    border-right: 1px dotted #fff;
                    padding-right: 25px;
                    color: #37b38f;
                }
            }
        }

        .vip-stick-div2 {
            display: flex;
            align-items: center;
            justify-content: right;

            div {
                background: linear-gradient(to right, #1A926E, #30B19D);
                height: 100%;
                width: 80%;
                color: white;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;

                &::before {
                    content: "";
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 10px 10px 10px 10px;
                    border-color: transparent transparent transparent #333;
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
        }
    }

    .user-info-box {
        background-color: #353535;
        height: 400px;
        padding: 12px;
        font-size: 14px;

        .user-fullname-title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 30px;
            color: white;

            div:last-child {
                font-size: 12px;
                color: #aaa;
            }
        }

        .user-info-title {
            font-size: 14px;
            color: white;

            #add-number-btn {
                padding: 3px 8px;
                background-color: green;
                color: white;
                border-radius: 3px;
                margin-left: 5px;
                font-size: 12px;
            }
        }

        .user-detail {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 40px;
            border-bottom: 0.5px solid #777;
            transition: 0.3s;
            border-left: 2px solid transparent;

            .detail-left {
                padding-left: 10px;
                color: #ccc;
            }

            .detail-right {
                padding-right: 10px;
                color: orange;
                text-decoration: underline;
            }

        }
    }
}

.pm-brc {
    border-color: rgb(255, 223, 26) !important;
}

.pl-filter {
    background-color: #262c32;

    padding: 8px;

    .dropdown-toggle {
        align-items: center;
        background: transparent !important;
        border-color: #ffc800;
        border-radius: 5px;
        color: #ffc800;
        display: flex;
        // font-size: 19px;
        font-weight: 500;
        justify-content: space-between;
        width: 100%;
        font-size: 15px;
        position: relative;
        padding: 5px 10px;
        text-transform: capitalize;
        font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

        &::after {
            background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgEAYAAAAj6qa3AAAAIGNIUk0AAHomAACAhAAA+gAAAIDoAAB1MAAA6mAAADqYAAAXcJy6UTwAAAAGYktHRAAAAAAAAPlDu38AAAAJcEhZcwAAAGAAAABgAPBrQs8AAAAHdElNRQfnBgENBAHr2nWeAAACD0lEQVRo3u2Yv0scQRTHP29js2I4IWVSeWkDKYRYCDEGCxFSSIqA/4XaeKKdZ+GpbdAmTZpoqkOsJAaE7JGTBNLedSm8QrQQQ0T3pdDxkIvZXdxfkPk2AwPvzft8dophwcbGxsbGBvU/KXR1qV//CoVC1vPEzqffFHp7DafZd9T33sDICOK2oNVCzi/g+FjVW4ONDdV6Hbq7swaIDnw5t6p3CJub8LsGR0eI+xEODgy3qF97BY0GoiUoFjs7yUvY3UXufYaxMZH+fjg9zRowCBy9eA5bW4juwNDQX7jK0Gw6oH3/vPKmwVXDvN6I0ODtij4oFBxExmFpKfCEnIqIDm54eAeVirQbeU9gYQFYh1IpxNkPYG8P/fUYRkfFeSFwcpI++PkHqFYRxmF4OLiQ17C6Ks7AT5iclM7G+RYRF7jZltsPypeIuMEDBeRFRFLgoQVkJSJp8MgC0hKRFriJE1WAyMAPmJ1F+Q6LiyFKDmFwEHEbsL19+RTt6cka/JonakHH+b73FsplhKcwMxOi5MaNQO7vg++nDR6bgLaIWhEqFUTfw9RUcMHVE/t6kpAPGJUJWF4W51kTpqfvOndsAtoiIt+IkI3j+eKJC4hdRELgiQu4s4iEwVOPEaHqeaB66+p7j2BlJet5kxOhtSLMz6t6VTg7u7nOzWU9X4oizB+bLw/BdbOex8bGxsbmf8wfxeWl3wymwowAAAAASUVORK5CYII=') !important;
            background-repeat: no-repeat;
            background-size: 13px;
            border: 0;
            height: 15px;
            transition: .3s;
            width: 23px;
            content: "";
            display: inline-block;
            margin-left: 0.255em;
            vertical-align: 0.255em;
        }

        &.show {
            &:after {
                transform: rotate(180deg);
            }
        }
    }

    .dropdown,
    .dropdown-center,
    .dropend,
    .dropstart,
    .dropup,
    .dropup-center {
        position: relative;
    }

    .bet-history-dropdown .btn.show:active,
    .bet-history-dropdown .dropdown-toggle:active,
    .bet-history-dropdown .dropdown-toggle:hover {
        background: transparent !important;
    }

    .dropdown-toggle.show,
    .dropdown-toggle:active,
    .dropdown-toggle:hover {
        border-color: #ffc800 !important;
        color: #ffc800 !important;
    }

    .dropdown-menu {
        background: #262c32;
        border: 1px solid #ffc800;
        border-radius: 0;
        border-radius: 0 0 8px 7px;
        border-top: 0;
        color: #ffc800;
        margin-top: -7px;
        width: 100%;
        position: absolute;
        inset: 0px auto auto 0px;
        transform: translate3d(0px, 36px, 0px);
        display: none;
        z-index: 999;

        &.show {
            display: block;
        }

        a {
            font-size: 15px;
            padding: 6px 12px;
            display: block;
            color: #ffc800;
            font-weight: 500;
            text-align: left;
        }
    }

    .pl-filter-first {
        align-items: center;
        display: flex;
        justify-content: space-between;

        &-time {
            align-items: center;
            border: 2px solid #ffc800;
            border-radius: 5px;
            display: flex;
            justify-content: space-evenly;
            padding: 3px;
            width: 65%;

            svg {
                color: #ffc800;
                font-size: 20px;
            }

            .truncate {
                color: #fff;
                font-size: 16px;

                @include bp(480px) {
                    font-size: 4vw;
                }
            }
        }

        button {
            background: #ffc800;
            background-color: #ffc800;
            border: none;
            color: #000;
            font-weight: 700;
            margin: 0 !important;
            font-size: 19.9733px;
            padding: 7px 14px;

            @include bp(480px) {
                font-size: 4vw;
                padding: 1vw 3vw;
            }
        }
    }

}

.bets-table {
    background-color: #e2eaef;

    td {
        background-color: #e2eaef !important;
        border-right: 1px solid #9cb1bd;
        border-top: 1px solid #9cb1bd;
        border-bottom: 1px solid #9cb1bd;

        &:last-child {
            border-right: 0;
        }
    }
}

.profit-loss-footer {
    td {
        padding: 4px 8px !important;
    }
}



.loader-main {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    .logo-box {
        width: 190px;
        height: 190px;
        background-color: #000;
        border-radius: 50%;
        box-shadow: 0 0 18px 8px #137F5D;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            // width: 80px;
            // height: 80px;
        }
    }
}

.home_wrp_promobanner {
    margin-bottom: 10px;

    .slick-slide img {
        height: 130px !important;
        object-fit: cover;
        border-radius: 10px;
    }

    .slick-track {
        margin: 0 -4px;

        .slick-slide {
            padding: 0 4px;
        }
    }

    .progressBarContainer {
        display: flex;
        justify-content: center;
        align-items: center;

        div {
            margin-right: 6px;
        }
    }
}


.noti-popup {
    .pop-content {
        margin: 170px auto !important;
    }

    .pop-head {
        background-color: #127E5C;

        h5 {
            color: #ffffff;
        }
    }

    .pop-body {
        p {
            font-size: 18px;
            color: #111;
        }

        .signup-login-btn {
            width: 100%;
            display: grid;
            grid-template-columns: 50% 50%;
            gap: 3px;
            margin-top: 20px;
        }

        div {
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 700;
            cursor: pointer;

        }

        .signup {
            background-color: #FFDF1A;
            font-size: 16px;
            padding: 12px;
        }

        .login {
            background-color: #127E5C;
            font-size: 16px;
            color: #ffffff;
            padding: 12px;
        }
    }
}

.refer-popup {
    .pop-content{
        height: calc(100vh - 30px);
    }
    .pop-body {
        background-color: #000;
      }
    .pop-head {
        background-color: #127E5C;

        h5 {
            color: #ffffff;
        }
    }
    ul {
        li {
            margin-bottom: 4px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 16px;
            color: #ffffff;
            font-weight: 500;
            div {
                background: #127E5C;
                font-size: 16px;
                color: #ffffff;
                padding: 6px;
                border-radius: 6px;
             
            }
        }
    }
}

.game-search {
    background-color: #111111;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9;
    display: flex;
    flex-direction: column;

    .search-bar {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #9cb1bd;

        .back {
            border-right: 1px solid #9cb1bd;
            height: 50px;
            width: 42px;
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 22px;
        }

        .search-fild {
            flex-grow: 1;

            input {
                background-color: transparent;
                color: #fff;
                border: 0;
                padding: 15px;
            }
        }

        .search-icon {

            height: 50px;
            width: 42px;
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 22px;
        }
    }

    .provider {
        padding: 12px;

        p {
            color: #ffffff;
            font-size: 14px;
            margin-bottom: 10px;
        }

        .row {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -2px;

            .col-4 {
                padding: 0 2px;
            }
        }

        .green-btn {
            background-color: #757575;
            color: #fff;
            font-size: 12px;
            border-radius: 2px;
            text-align: center;
            padding: 8px;
            margin-bottom: 4px;

            &.active {
                background-color: #14805E;
            }
        }
    }

    .game-type {
        padding: 12px;

        p {
            color: #ffffff;
            font-size: 14px;
            margin-bottom: 10px;
        }

        .row {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -2px;

            .col-4 {
                padding: 0 2px;
            }
        }

        .green-btn {
            background-color: #757575;
            color: #fff;
            font-size: 12px;
            border-radius: 2px;
            text-align: center;
            padding: 8px;
            margin-bottom: 4px;

            &.active {
                background-color: #14805E;
            }
        }
    }

    .confirm-btn {
        padding: 12px;
        margin-top: auto;

        .btn-c {
            font-size: 16px;
            color: #ffffff;
            padding: 10px;
            border-radius: 3px;
            border-color: #14805E;
            text-align: center;
            width: 100%;
            background-color: #14805E;
        }
    }
}

.filter-tr {
    padding: 0 0 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #484848;

    .badge {
        background-color: #14805E;
        font-size: 12px;
        border-radius: 4px;
    }

    .search-icon {
        height: 50px;
        width: 42px;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 22px;
        border-left: 1px solid #9cb1bd;
    }
}

.tr-rc-table {
    background-color: #111111;

    table {
        text-align: center;

        thead {
            background-color: #14805E;

            tr {
                background-color: #111111 !important;

                th {
                    border-left: 1px solid #9cb1bd;

                }
            }
        }

        tr:nth-child(odd) {
            background-color: #484848;
        }

        tr:nth-child(even) {
            background-color: #111111;
        }

        th,
        td {
            color: #ffffff;
            padding: 8px;
        }

        .outline-tag {
            border: 1px solid #9cb1bd;
            padding: 4px;
            color: #ffffff;
            border-radius: 4px;
            display: inline-block;
        }

        .success-tag {
            padding: 4px;
            background-color: #127E5C;
            border-radius: 4px;
            display: block;
        }

        .fail-tag {
            padding: 4px;
            background-color: #fe3434;
            border-radius: 4px;
            display: block;
        }

        .pending-tag {
            padding: 4px;
            background-color: #fec834;
            border-radius: 4px;
            display: block;
        }
    }
}

.promotion-page {
    background-color: #111111;

    .filter-list {
        display: flex;
        overflow-x: auto;
        gap: 4px;
        padding: 10px;

        .badge {
            padding: 6px 14px;
            background-color: #484848;
            color: #ffffff;
            font-size: 11px;

            &.active {
                background-color: #14805E;
            }
        }
    }

    .add-code {
        padding: 12px;

        p {
            font-size: 14px;
            color: #ffffff;
        }

        .apply {
            background-color: #484848;
            border-radius: 4px;
            display: flex;
            align-items: stretch;
            justify-content: space-between;
            margin-top: 10px;

            input {
                background-color: transparent;
                border: none;
                padding: 10px;

                &::placeholder {
                    color: #a0a0a0;
                    font-size: 14px;
                }
            }

            .apply-btn {
                background-color: #14805E;
                color: #ffffff;
                border-radius: 0 4px 4px 0;
                font-size: 14px;
                border: none;
                padding: 12px;
            }
        }
    }

    .promotion-list {
        padding: 14px;

        .promotion-box {
            background-color: #ffffff;
            border-radius: 8px;
            position: relative;
            margin-bottom: 15px;

            .new-tag {
                position: absolute;
                top: 10px;
                left: 10px;
                background-color: #127E5C;
                color: #fff;
                font-size: 10px;
                padding: 4px 10px;
                border-radius: 4px;
            }

            img {
                border-radius: 8px 8px 0 0;
            }

            .pr-info {
                padding: 8px;
            }

            h5 {
                font-size: 14px;
                color: #111111;
                margin-bottom: 8px;
            }

            .btn-depo-deta {
                margin-top: 10px;
                display: flex;
                align-items: center;
                gap: 8px;

                .deposit {
                    background-color: #14805E;
                    color: #ffffff;
                    padding: 12px;
                    border: none;
                    border-radius: 4px;
                    width: 100%;
                }

                .detail {
                    background-color: #1b56c7;
                    color: #ffffff;
                    padding: 12px;
                    border: none;
                    border-radius: 4px;
                    width: 100%;
                }
            }
        }
    }
}

.cash-out-pay {
    background-color: #ffffff;

    .page-header {
        box-shadow: 0 .2666666667vw .8vw #0000001a;
        padding: 10px 20px;

        h5 {
            font-size: 16px;
            color: #14805e;
            font-weight: 700;
            line-height: 1.2;
        }
    }

    .page-content {
        padding: 5px 20px;
        border-bottom: 1px solid #ddd;

        p {
            font-size: 15px;
            color: #333333;

            &.gray {
                color: #666666;
            }

            &.dark {
                font-size: 16px;

                span {
                    color: #ff4b11;
                }
            }
        }

        .contact-form {
            padding: 10px 10px 20px 10px;
            background-color: #f3f3f3;
            border: 1px solid #ddd;
            border-radius: 20px;

            img {
                width: 148px;
                margin: 0 auto;
            }

            .wallet-num {
                font-size: 37px;
                color: #14805e;
                line-height: 42px;
                margin-top: 15px;
                margin-bottom: 5px;
            }

            .copy-btn {
                position: relative;
                display: inline-flex;
                align-items: center;
                height: 1.625rem;
                transition: transform .3s;
                padding: .25rem .5rem;
                font-size: .75rem;
                letter-spacing: 0;
                border-radius: 1.25rem;
                border: .0625rem solid #ffbf44;
                background: rgba(255, 191, 68, .1);
                color: #ffbf44;
                width: auto;
            }

            .mr {
                margin-right: 4px;
            }

            .amount {
                font-size: 17px;
                color: #333333;
                margin: 5px 0;

                span {
                    color: #ff4b11;
                }
            }

            form {
                margin-top: 20px;

                .form-group {
                    margin-bottom: 10px;
                }

                label {
                    color: #14805e;
                    font-size: 14px;
                    line-height: 1;
                    display: block;
                    text-align: left;

                    .reset {
                        display: inline-block;
                        padding: .125rem .375rem;
                        border: 1px solid #ff4b11;
                        border-radius: 2px;
                        background-color: transparent;
                        color: #ff4b11;
                        font-size: 12px;
                        cursor: pointer;
                    }
                }

                input {
                    margin-top: .3125rem;
                    padding: .4375rem .875rem;
                    border: 1px solid #eee;
                    background-color: #ffffff;
                    border-radius: 3px;
                    font-size: 14px;
                    display: block;
                    width: 100%;
                }

                .rec-txt {
                    color: #ff4b11;
                    font-size: .9rem;
                    margin-top: 4px;
                    text-align: left;
                }

                .dropImages {
                    width: 100%;
                    height: 5.625rem;
                    margin: .3125rem 0;
                    border: 1px solid #ebebeb;
                    border-radius: 3px;
                    font-size: 1.25rem;
                    line-height: 5.625rem;
                    text-align: center;
                    color: #000;
                }

                .submit-btn {
                    background-color: #14805E;
                    color: white;
                    padding: 13px;
                    border: none;
                    border-radius: 4px;
                    min-width: 168PX;
                    margin-top: 10px;
                    font-size: 16px;
                    line-height: 14px;
                }
            }
        }
    }
}


@keyframes toolbar-active-animation {
    0% {
        transform: translate(-50%, -50%) scale(0)
    }

    85% {
        transform: translate(-50%, -50%) scale(1.2)
    }

    90% {
        transform: translate(-50%, -50%) scale(.9)
    }

    95% {
        transform: translate(-50%, -50%) scale(1.1)
    }

    to {
        transform: translate(-50%, -50%) scale(1)
    }
}



.styles-module_tooltip__mnnfp {
    padding: 0 !important;
    opacity: 1 !important;
    border: 1px solid #ececec;

    .upper {

        padding: 8px 14px;
        border-radius: 3px;
        margin-bottom: 0px;
        font-size: 14px;
        text-align: center;
    }

    .lower {
        background-color: white;
        color: black;
        padding: 3px 5px;
        border-radius: 0 0 3px 3px;
        font-size: 14px;
        width: 100%;
        text-align: center;
    }

    .core-styles-module_arrow__cvMwQ {
        z-index: -1;
        border: 1px solid #c6c6c6;
        background-color: #ffffff;
    }
}

.welcome-popup {
    padding: 20px;

    img {
        width: 100px;
    }

    .pop-content {
        margin: 110px auto !important;
        text-align: center;

        .green-text {
            color: #127E5C;
            margin-bottom: 15px;
        }

        h3 {
            font-size: 50px;
            color: #ff8b14;
            font-weight: 100;
        }

        p {
            font-size: 18px;
            color: #000;
            font-weight: 100;
        }

        .home {
            background: #ff8b14;
            font-size: 16px;
            color: #ffffff;
            padding: 12px;
            margin-top: 15px;

        }

        .deposit {
            background: #127E5C;
            font-size: 16px;
            color: #ffffff;
            padding: 12px;
            margin-top: 15px;
        }
    }
}