.account {
    // margin-bottom: 50px;
    width: 1350px;
    padding: 0;
    margin-top: 1px;
    #getPL {
        padding: 2px 20px !important;
    }
    .top_header {
        display: none;
    }
    &_tabs {
        display: flex;
        &_l {
            flex: 0 0 auto;
            width: 235px;
            &_list {
                display: flex;
                flex-direction: column;
                padding-left: 0;
                margin-bottom: 0;
                border-radius: 4px;
                &_item {
                    a {
                        position: relative;
                        display: block;
                        padding: 0.5rem 1rem;
                        color: #212529;
                        text-decoration: none;
                        background-color: #fff;
                        border: 1px solid rgba(0, 0, 0, 0.125);
                        &:hover,
                        &:focus {
                            z-index: 1;
                            color: #495057;
                            text-decoration: none;
                            background-color: #f8f9fa;
                        }
                    }
                    &.active {
                        a {
                            background-color: $menucolor1;
                            color: $menufontcolor;
                            border-color: $menucolor1;
                            font-weight: bold;
                        }
                    }
                }
            }
        }
        &_r {
            flex: 0 0 auto;
            width: calc(100% - 255px);
            padding-left: 15px;
            &_title {
                background-color: rgba(0, 0, 0, 0.03);
                border-bottom: 1px solid rgba(0, 0, 0, 0.125);
                border-radius: calc(4px - 1px) calc(4px - 1px) 0 0;
                margin-bottom: 5px;
                display: block;
                strong {
                    line-height: 18px;
                }
            }
            &_d {
                &_l {
                    flex: 0 0 auto;
                    width: 50%;
                    padding-right: 15px;
                    &:last-child {
                        padding-right: 0;
                    }
                    &_item {
                        position: relative;
                        display: flex;
                        flex-direction: column;
                        min-width: 0;
                        width: 100%;
                        word-wrap: break-word;
                        background-color: $white;
                        background-clip: border-box;
                        border: 1px solid rgba(0, 0, 0, 0.125);
                        border-radius: 4px;
                        margin-bottom: 10px;
                        &_wrp {
                            width: 100%;
                            flex-wrap: wrap;
                        }
                        &-header {
                            background-color: #7e97a7;
                            padding: 5px;
                            font-size: 14px;
                            border-radius: calc(4px - 1px) calc(4px - 1px) 0 0;
                            color: $white;
                            font-weight: 500;
                        }
                    }
                    &right {
                        flex: 0 0 auto;
                        width: 50%;
                        padding-left: 15px;
                    }
                }
            }
            &_bet {
                margin-bottom: 20px;
                ul {
                    li {
                        background-color: $white;
                        padding: 5px 48px;
                        background: unset;
                        margin: 0;
                        &.active {
                            background: #3b5160 !important;
                            color: $white;
                        }
                        &:nth-child(2) {
                            border-top-left-radius: 0;
                            border-bottom-left-radius: 0;
                            border-left: 0;
                        }
                        &.gray {
                            &:hover {
                                background: #3b5160 !important;
                                color: white;
                            }
                        }
                    }
                }
                &_content {
                    .nav {
                        display: flex;
                        flex-wrap: wrap;
                        padding-left: 0;
                        margin-bottom: 0;
                        list-style: none;
                        &-tabs {
                            width: 100%;
                            margin-top: 20px;
                            margin-bottom: 0px;
                            border-bottom: 5px solid #3b5160;
                            .nav-item {
                                margin-left: 5px;
                                display: inline-block;
                                &:first-child {
                                    margin-left: 0px;
                                }
                                .nav-link {
                                    border: 1px solid rgb(59, 81, 96);
                                    border-bottom: 0;
                                    font-size: 13px;
                                    line-height: 16px;
                                    font-weight: 700;
                                    padding: 0;
                                    text-align: center;
                                    padding: 5px 24px 5px;
                                    border-radius: 3px 3px 0 0;
                                    color: #3b5160;
                                    text-decoration: none;
                                    display: inline-block;
                                    &.active {
                                        background: rgb(59, 81, 96);
                                        color: #ffffff !important;
                                    }
                                }
                            }
                        }
                    }
                    .function-wrap {
                        width: 100%;
                        background: #e4e4e4;
                        position: relative;
                        padding: 15px 10px;
                        border-bottom: 1px solid #7e97a7;
                        margin-bottom: 10px;
                        .inputlist {
                            display: flex;
                            align-items: center;
                            margin-bottom: 10px;
                            &:last-child {
                                margin-bottom: 0;
                            }
                            li {
                                margin-right: 5px;
                                select {
                                    padding: 2px 10px;
                                }
                                span {
                                    margin-right: 5px;
                                }
                                input.form-control1 {
                                    width: 110px;
                                    cursor: pointer;
                                    font-family: Tahoma, Helvetica, sans-serif;
                                    color: #1e1e1e;
                                    font-size: 12px;
                                    border: 0px #aaa solid;
                                    box-shadow: inset 0px 1px 0px #00000080;
                                    border-radius: 4px;
                                    box-sizing: border-box;
                                    line-height: 19px;
                                    padding: 1px 2px;
                                    margin-right: 5px;
                                    &.no-calender {
                                        max-width: 50px;
                                    }
                                }
                                .btn-default-customize {
                                    font-weight: 400;
                                    text-align: center;
                                    border: 1px solid #bbb;
                                    border-radius: 4px;
                                    line-height: 1;
                                    font-size: 12px;
                                    padding: 5px 10px;
                                    display: block;
                                    background-color: $white;
                                    &:hover {
                                        color: #212529;
                                    }
                                }
                                a {
                                    margin: 0;
                                }
                            }
                        }
                    }
                    .margin-table {
                        p {
                            margin-bottom: 16px !important;
                        }
                    }
                }
            }
        }
        &_filter {
            margin: 0 -12px;
            margin-bottom: 15px;
            &_item {
                flex: 0 0 auto;
                width: 25%;
                padding: 0 12px;
                &:nth-child(3) {
                    flex: 0 0 auto;
                    width: 16.66666667%;
                }
                &:last-child {
                    flex: 0 0 auto;
                    width: 8.33333333%;
                }
                label {
                    display: block;
                    width: 100%;
                    margin-bottom: 2px;
                }
                .input_group {
                    position: relative;
                    display: flex;
                    // flex-wrap: wrap;
                    align-items: stretch;
                    width: 100%;
                    >.form-control,
                    >.form-select {
                        position: relative;
                        flex: 1 1 auto;
                        width: 1%;
                        min-width: 0;
                    }
                    &:not(.has-validation)> :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
                        border-top-right-radius: 0;
                        border-bottom-right-radius: 0;
                    }
                    &> :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
                        margin-left: -1px;
                        border-top-left-radius: 0;
                        border-bottom-left-radius: 0;
                    }
                }
                .submit-btn {
                    padding: 8px !important;
                    box-shadow: none !important;
                }
            }
        }
    }
    .table {
        width: 100% !important;
        border-collapse: collapse;
        margin-bottom: 15px;
        tbody {
            vertical-align: inherit;
            td {
                border-color: #dee2e6;
                svg {
                    width: 12px;
                    path {
                        fill: #0d6efd !important;
                    }
                }
            }
        }
        > :not(caption)>*>* {
            padding: 0.5rem 0.5rem;
            background-color: transparent;
            border-bottom-width: 1px;
            box-shadow: inset 0 0 0 9999px transparent;
        }
    }
}
.accountMobile {
    padding: 0;
    padding-bottom: 30px;
    .path-wrap {
        background-color: #1e1e1e;
        color: #fff;
        font-weight: 700;
        line-height: 10.66667vw;
        display: flex;
        .account-id {
            width: 100%;
            padding-left: 1.86667vw;
            align-items: center;
            display: flex;
            &::before {
                content: "";
                background: url('../../../public/images/account_user.svg') no-repeat;
                width: 5.33333vw;
                height: 5.33333vw;
                margin-right: 1.33333vw;
                display: block;
                background-size: contain;
            }
            span {
                &:first-of-type {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    flex: 1;
                }
                &.gmt {
                    flex: 0 0 auto;
                    min-width: 17.33333vw;
                    border-left: 1px solid #4b4b4b;
                    padding: 0 1.86667vw;
                    margin-left: 1.86667vw;
                }
            }
        }
    }
    ul {
        background-color: #fff;
        border-bottom: 1px solid #7e97a7;
        margin-bottom: 20px;
        li {
            border-bottom: 1px solid #e0e6e6;
            list-style: none;
            align-items: center;
            a {
                flex: 1;
                position: relative;
                font-size: 4vw;
                font-weight: 700;
                line-height: 1.6;
                padding: 2.13333vw 9.06667vw 2.13333vw 2.66667vw;
                flex-wrap: wrap;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                align-items: center;
                box-sizing: border-box;
                display: block;
                color: #2789ce;
                &::after {
                    position: absolute;
                    top: 1.86667vw;
                    right: 2.66667vw;
                    content: "";
                    width: 6.4vw;
                    height: 6.4vw;
                    background: #fff url('../../../public/images/right_arrow.svg') no-repeat;
                    background-size: contain;
                    border: 1px solid #e0e6e6;
                    border-radius: 1.06667vw;
                }
            }
        }
    }
    .logout {
        height: 12.8vw;
        background-image: linear-gradient(-180deg, #e93522, #be2414);
        border: 1px solid #8a0011;
        border-width: 1px 0;
        color: #fff;
        font-size: 4.26667vw;
        font-weight: 700;
        margin-bottom: 5.33333vw;
        justify-content: center;
        align-items: center;
        display: flex;
        span {
            width: 13px;
            height: 14px;
            margin-left: 5px;
            background-image: url("../../../public/images/logout.svg");
            background-repeat: no-repeat;
        }
    }
}
.account {
    margin-bottom: 50px;
    &_tabs {
        display: flex;
        @include bp(mini) {
            display: flex;
            // flex-wrap: wrap;
        }
        &_l {
            @include bp(mini) {
                padding: 0 10px;
            }
        }
        &_r {
            flex: 0 0 auto;
            // width: 75%;
            // padding-left: 15px;
            // @include bp(mini) {
            //     width: 100%;
            //     margin-top: 20px;
            //     padding: 0 10px;
            // }
            &_title {
                background-color: rgba(0, 0, 0, 0.03);
                border-bottom: 1px solid rgba(0, 0, 0, 0.125);
                border-radius: calc(4px - 1px) calc(4px - 1px) 0 0;
                margin-bottom: 5px;
                display: block;
                strong {
                    line-height: 18px;
                }
            }
            .popup-wrp {
                .pop {
                    max-width: 800px;
                    &-body {
                        padding: 0;
                        margin: 0;
                    }
                    table {
                        width: 100% !important;
                        border-collapse: collapse;
                        margin-bottom: 15px;
                        border: 1px solid #dee2e6;
                        &.table-bordered>:not(caption)>*>* {
                            border-width: 1px !important;
                            padding: 0.5rem 0.5rem;
                            border-color: #dee2e6;
                            line-height: 18px;
                        }
                        .text-color-green {
                            color: #508d0e !important;
                        }
                        .text-color-red {
                            color: #8d0e0e !important;
                        }
                        .text-left {
                            text-align: left;
                        }
                    }
                    .submit-btn {
                        display: none;
                    }
                    .button-wrap {
                        justify-content: flex-end;
                    }
                }
            }
        }
    }
    .table {
        width: 100% !important;
        border-collapse: collapse;
        margin-bottom: 15px;
        tbody {
            vertical-align: inherit;
            td {
                border-color: #dee2e6;
                svg {
                    width: 12px;
                    path {
                        fill: #0d6efd !important;
                    }
                }
            }
        }
        > :not(caption)>*>* {
            padding: 0.5rem 0.5rem;
            background-color: transparent;
            border-bottom-width: 1px;
            box-shadow: inset 0 0 0 9999px transparent;
        }
    }
}